import { Wrapper } from '@/components/modules';

import { StyledWrapper } from '../../modules/ExposeHeroImage/ExposeHeroImage.styled';
import {
  ActionButtonRightcontainer,
  StyledActionBarSkeletonContainer,
  StyledContentContainer,
  StyledContentWrapper,
  StyledImageSkeletonContainer,
  StyledLeftColumn,
  StyledRightColumn,
  StyledSkeletonDiv,
} from './ExposeSkeletonLoading.styles';

export const ExposeSkeletonLoading = () => {
  return (
    <>
      <StyledWrapper width={{ tabletLandscape: 'l', laptop: 'l' }}>
        <StyledActionBarSkeletonContainer>
          <StyledSkeletonDiv
            height="var(--distance-static-xxl)"
            width="var(--distance-static-3xl)"
          />
          <ActionButtonRightcontainer>
            <StyledSkeletonDiv
              height="var(--distance-static-xxl)"
              width="var(--distance-static-3xl)"
            />
            <StyledSkeletonDiv
              height="var(--distance-static-xxl)"
              width="var(--distance-static-3xl);"
            />
          </ActionButtonRightcontainer>
        </StyledActionBarSkeletonContainer>

        <StyledImageSkeletonContainer>
          <StyledLeftColumn>
            <StyledSkeletonDiv />
          </StyledLeftColumn>

          <StyledRightColumn>
            <StyledSkeletonDiv />
            <StyledSkeletonDiv />
          </StyledRightColumn>
        </StyledImageSkeletonContainer>
      </StyledWrapper>
      <Wrapper as="main" width={{ laptop: 'l', tabletLandscape: 'l' }}>
        <StyledContentWrapper>
          <StyledContentContainer>
            <StyledSkeletonDiv height="var(--distance-static-m)" width="40%" />
            <StyledSkeletonDiv height="var(--distance-static-l)" width="80%" />
            <StyledSkeletonDiv height="var(--distance-static-l)" width="50%" />
            <StyledSkeletonDiv height="var(--distance-static-l)" width="50%" />

            <StyledSkeletonDiv
              height="var(--distance-static-xxs)"
              width="100%"
            />
            <StyledContentContainer flex={0} flexDirection="row">
              <StyledSkeletonDiv height="var(--distance-static-l)" />
              <StyledSkeletonDiv height="var(--distance-static-l)" />
              <StyledSkeletonDiv height="var(--distance-static-l)" />
            </StyledContentContainer>

            <StyledSkeletonDiv
              height="var(--distance-static-xxs)"
              width="100%"
            />

            <StyledSkeletonDiv height="var(--distance-static-l)" width="50%" />
            <StyledSkeletonDiv height="var(--distance-static-l)" width="60%" />
            {new Array(4).fill(0).map((_, index) => (
              <StyledSkeletonDiv
                key={index}
                height="var(--distance-static-l)"
                width="100%"
              />
            ))}
          </StyledContentContainer>

          <StyledContentContainer
            display="none"
            justifyContent="flex-end"
            flexDirection="row"
          >
            <StyledSkeletonDiv height="40vh" width="50%" />
          </StyledContentContainer>
        </StyledContentWrapper>
      </Wrapper>
    </>
  );
};
