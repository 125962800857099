import { css } from 'styled-components';

const backgroundColorVariants = {
  default: css``,
  colored: css`
    background-color: var(--color-surface-light-highlight);
  `,
  grey: css`
    background-color: var(--color-surface-light-subdued);
  `,
} as const;

export type BackgroundColorVariants = keyof typeof backgroundColorVariants;

const coastToCoastStyles = css`
  margin-bottom: var(--distance-dynamic-s);
  padding-top: var(--distance-dynamic-l);
`;

export const backgroundColor = ({
  backgroundColor,
  coastToCoast,
}: {
  backgroundColor?: BackgroundColorVariants;
  coastToCoast?: boolean;
}) => {
  return css`
    ${coastToCoast && backgroundColor !== 'default' ? coastToCoastStyles : ''};
    ${backgroundColor && backgroundColorVariants[backgroundColor]};
  `;
};
