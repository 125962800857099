import { type Property } from '@ev/search-modules-api';
import { type SearchModuleFilters } from '@ev/search-modules-components';
import { styled } from '@mui/material';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { type CommonPageProps } from 'pages/_app';
import { type ReactElement, useEffect, useMemo, useState } from 'react';

import { type HeaderProps } from '@/components/common/Header/Header';

import { ExposeSkeletonLoading } from '../redesign/elements/ExposeSkeletonLoading/ExposeSkeletonLoading';

const HeaderBlokDynamic = dynamic(() =>
  import('@/blocks').then((mod) => mod.HeaderBlock)
);

const Header = dynamic(() =>
  import('@/components/common/Header/Header').then((mod) => mod.Header)
);

const FooterBlokDynamic = dynamic(() =>
  import('@/blocks').then((mod) => mod.FooterBlock)
);

const Footer = dynamic(() =>
  import('@/components/common/Footer/Footer').then((mod) => mod.Footer)
);

const StickyContainer = styled('div')<{ $isLegacyHeader: boolean }>(
  ({ $isLegacyHeader }) => ({
    position: 'sticky',
    top: 0,
    zIndex: 3,
    background: 'var(--color-surface-light)',
    paddingBottom: $isLegacyHeader ? '0.75rem' : '0',
  })
);

type Props = HeaderProps & {
  children: React.ReactNode;
  stickyHeader?: boolean;
  withContactButton?: boolean;
  property?: Property;
  filters?: SearchModuleFilters;
  isPropertySearchPage?: boolean;
};

export const Skeleton = ({
  children,
  spearHeadNavigation,
  onLanguageChange,
  pageLanguages,
  stickyHeader,
  withContactButton,
  externalLinkDomains,
  property,
  filters,
  isPropertySearchPage,
}: Props & CommonPageProps): ReactElement => {
  const {
    i18n: { language },
  } = useTranslation();
  const router = useRouter();

  const [loadingPage, setLoadingPage] = useState('');

  const evLogoUrl = `https://www.engelvoelkers.com/${router.query.countryCodeOrLocale}/${language}`;

  useEffect(() => {
    const handleRouteChangeStart = (route: string) => {
      if (route) {
        const routeInLowerCase = route.toLowerCase();
        const routes = ['exposes', 'projects'];

        if (routes.some((route) => routeInLowerCase.includes(route))) {
          setLoadingPage('exposes');
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      }
    };

    const handleRouteChangeComplete = () => setLoadingPage('');

    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, [router]);

  const loadingComponent = useMemo(() => {
    switch (loadingPage) {
      case 'exposes':
        return <ExposeSkeletonLoading />;
      default:
        return null;
    }
  }, [loadingPage]);

  const ConditionalStickyWrapper = ({
    children,
    $isLegacyHeader = false,
  }: {
    children: React.ReactNode;
    $isLegacyHeader?: boolean;
  }) =>
    isPropertySearchPage ? (
      <StickyContainer $isLegacyHeader={$isLegacyHeader}>
        {children}
      </StickyContainer>
    ) : (
      children
    );

  return (
    <>
      {spearHeadNavigation?.headerData ? (
        <ConditionalStickyWrapper>
          <HeaderBlokDynamic
            blok={spearHeadNavigation.headerData}
            className="sp-header"
            baseSlug={evLogoUrl}
            pageLanguages={pageLanguages}
            onLanguageChange={onLanguageChange}
            sticky={stickyHeader}
            withContactButton={withContactButton}
            externalLinkDomains={externalLinkDomains}
            property={property}
            filters={filters}
          />
        </ConditionalStickyWrapper>
      ) : (
        <ConditionalStickyWrapper $isLegacyHeader>
          <Header onLanguageChange={onLanguageChange} />
        </ConditionalStickyWrapper>
      )}
      {loadingComponent ?? children}
      {spearHeadNavigation?.footerData ? (
        <FooterBlokDynamic
          blok={spearHeadNavigation.footerData}
          externalLinkDomains={externalLinkDomains}
        />
      ) : (
        <Footer />
      )}
    </>
  );
};
