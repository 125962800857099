import { type ReactNode, useRef } from 'react';
import { type AriaButtonProps, useButton } from 'react-aria';

import { StyledImageButton } from './ImageButton.styled';

interface ImageButtonProps extends AriaButtonProps {
  className?: string;
  children: ReactNode;
}

export const ImageButton = ({
  className,
  children,
  ...props
}: ImageButtonProps) => {
  const ref = useRef<HTMLButtonElement>(null);
  const { buttonProps } = useButton(props, ref);

  return (
    <StyledImageButton {...buttonProps} className={className} ref={ref}>
      {children}
    </StyledImageButton>
  );
};
