import { CarouselImage } from '@ev/search-modules-components';
import Image from 'next/image';
import styled, { css } from 'styled-components';

import { Copy } from '@/components/elements';
import { Wrapper } from '@/components/modules';
import {
  maxTabletLandscape,
  mobile,
  tabletLandscape,
} from '@/components/utils';
import { NO_IMAGES_BACKGROUND } from '@/core/utils/propertyDataUtils/propertyDataUtils';

import { Button } from '../../elements/Button/Button';
import { ImageButton } from '../../elements/ImageButton/ImageButton';
import { ImageButtonHoverStyles } from '../../elements/ImageButton/ImageButton.styled';
import { from, to } from '../../utils/mediaQueries';
import { type AspectRatio } from './ExposeHeroImage.types';

export const StyledWrapper = styled(Wrapper)`
  padding-top: 0;
  padding-bottom: var(--distance-static-s);
  @media screen and (${maxTabletLandscape}) {
    padding: 0;
  }
`;

export const StyledNoImagesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  aspect-ratio: 16/3;
  width: 100%;
  height: 255px;
  background-image: url(${NO_IMAGES_BACKGROUND});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

export const StyledCopy = styled(Copy)`
  text-align: center;
  color: var(--color-dark-subdued);
  background-color: var(--color-light);
  padding: var(--distance-static-s) var(--distance-static-m);
  margin: 0 var(--distance-static-m);
`;

const templateColumns = [
  css`
    @media screen and (${tabletLandscape}) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  `,
  css`
    @media screen and (${tabletLandscape}) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  `,
  css`
    @media screen and (${tabletLandscape}) {
      // 3/2 and 3/4 === 6/4 and 3/4
      // 6/4 + 3/4 = 9/4
      // because of the gaps we need to calculate the width of the columns relative to the grid
      grid-template-columns: calc(100% / 9 * 6 - 3px) calc(100% / 9 * 3 - 13px);
    }
  `,
];

const templateAreas = [
  css`
    grid-template-areas: 'bigImage-0';
  `,

  css`
    grid-template-areas: 'bigImage-0' 'bigImage-1';

    @media screen and (${tabletLandscape}) {
      grid-template-areas: 'bigImage-0 bigImage-1';
    }
  `,
  css`
    grid-template-areas: 'bigImage-0 bigImage-0';

    @media screen and (${tabletLandscape}) {
      grid-template-areas: 'bigImage-0 smallImage-0' 'bigImage-0 smallImage-1';
    }
  `,
];

export const StyledImageGalleryRow = styled.div<{ $imageCount: number }>`
  position: relative;
  display: grid;
  gap: var(--distance-static-m);

  ${({ $imageCount }) => templateColumns[$imageCount]}

  ${({ $imageCount }) => templateAreas[$imageCount]}

   @media screen and (${to.laptop}) {
    display: none;
  }
`;

export const StyledImage = styled(Image)<{ $objectFit: 'cover' | 'contain' }>`
  object-fit: ${({ $objectFit }) => $objectFit};
`;

export const StyledImageWrapper = styled(ImageButton)<{
  area: string;
  aspectRatio: AspectRatio;
}>`
  position: relative;
  background-color: var(--color-light-shade);

  ${ImageButtonHoverStyles}

  &:nth-of-type(2) {
    display: none;

    @media screen and (${from.laptop}) {
      display: block;
    }
  }

  &:nth-of-type(3) {
    display: none;

    @media screen and (${from.laptop}) {
      display: block;
    }
  }

  ${({ aspectRatio }) => css`
    aspect-ratio: ${aspectRatio};
  `}

  @media screen and (${mobile}) {
    aspect-ratio: 16/9;
  }

  ${({ area }) =>
    css`
      grid-area: ${area};
    `}

  @media screen and (${to.laptop}) {
    all: unset;
    display: none;
  }
`;

export const StyledButtonGroup = styled.div`
  display: flex;
  position: absolute;
  bottom: var(--distance-static-m);
  right: var(--distance-static-m);
  gap: var(--distance-static-xs);
  z-index: 1;
`;

const btnStyles = css`
  line-height: var(--line-height-static-m);
  box-shadow: var(--shadow-s);
  padding: var(--distance-static-xs);
`;

export const StyledButton = styled(Button)`
  ${btnStyles}

  @media screen and (${to.laptop}) {
    display: none;
  }

  & > span {
    display: none;
  }
`;

export const StyledImageGalleryButtonMobile = styled(Button)`
  ${btnStyles};
  @media screen and (${from.tabletLandscape}) {
    display: none;
  }
`;

export const StyledImageGalleryButtonTablet = styled(Button)`
  ${btnStyles};
  @media screen and (${to.tabletLandscape}), (${from.laptop}) {
    display: none;
  }
`;

export const StyledImageGalleryButtonDesktop = styled(Button)`
  ${btnStyles};
  @media screen and (${to.laptop}) {
    display: none;
  }
`;

export const StyledPropertyImageSlider = styled.div`
  position: relative;

  @media screen and (${from.laptop}) {
    display: none;
  }
`;

export const StyledCarouselImage = styled(CarouselImage)`
  aspect-ratio: 3/2;
  img {
    aspect-ratio: inherit;
  }
  @media screen and (${from.tabletPortrait}) {
    aspect-ratio: 16/9;
  }
`;
